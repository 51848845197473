"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _omit = require('lodash/omit'); var _omit2 = _interopRequireDefault(_omit);
var _baseline = require('./baseline');
var _constants = require('./constants');
var _merge = require('lodash/merge'); var _merge2 = _interopRequireDefault(_merge);
var _buttonvariants = require('./variants/button-variants');
var _tablevariants = require('./variants/table-variants');
var _tooltipvariants = require('./variants/tooltip-variants');
var _inputbasevariants = require('./variants/input-base-variants');
var _dividervariants = require('./variants/divider-variants');
var _appbarvariants = require('./variants/appbar-variants');
var _typography = require('./typography');
var _papervariants = require('./variants/paper-variants');
var _selectvariants = require('./variants/select-variants');
var _svgiconvariants = require('./variants/svgicon-variants');
var _accordionvariants = require('./variants/accordion-variants');
var _chipvariants = require('./variants/chip-variants');
var _togglebuttongroupvariants = require('./variants/toggle-button-group-variants');
var _linearprogressvariants = require('./variants/linear-progress-variants');
var _cardvariants = require('./variants/card-variants');
var _alertvariants = require('./variants/alert-variants');
var _drawervariants = require('./variants/drawer-variants');
const variantGenerators = [_drawervariants.buildDrawerVariant, _buttonvariants.buildButtonVariant, _tablevariants.buildTableVariant, _tooltipvariants.buildTooltipVariant, _dividervariants.buildDividerVariant, _papervariants.buildPaperVariant, _inputbasevariants.buildInputBaseVariant, _selectvariants.buildSelectVariant, _svgiconvariants.buildSvgIconVariant, _accordionvariants.buildAccordionVariant, _chipvariants.buildChipVariant, _togglebuttongroupvariants.buildToggleButtonGroupVariant, _cardvariants.buildCardVariant, _linearprogressvariants.buildLinearProgressVariant, _alertvariants.buildAlertVariant, _appbarvariants.buildAppBarVariant];
const lightModeVariants = variantGenerators.reduce((acc, generator) => _merge2.default.call(void 0, acc, generator("light")), {});
const darkModeVariants = variantGenerators.reduce((acc, generator) => _merge2.default.call(void 0, acc, generator("dark")), {});
const baseComponents = {
  MuiCssBaseline: _baseline.MuiCssBaseline,
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: "none"
      },
      outlined: {
        outlineStyle: "solid"
      }
    }
  },
  MuiList: {
    styleOverrides: {
      root: {
        padding: _constants.SPACING.call(void 0, 3),
        gap: _constants.SPACING.call(void 0, 1),
        display: "flex",
        flexDirection: "column"
      }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        display: "flex",
        alignItems: "center",
        gap: _constants.SPACING.call(void 0, 2),
        padding: _constants.SPACING.call(void 0, 2),
        ..._omit2.default.call(void 0, _typography.buildTypographyVariant.call(void 0, "dark").bodySmallRegular, "color")
      }
    }
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        ..._omit2.default.call(void 0, _typography.buildTypographyVariant.call(void 0, "dark").bodySmallRegular, "color")
      }
    }
  },
  MuiLink: {
    styleOverrides: {
      root: {
        display: "flex",
        alignItems: "center",
        gap: _constants.SPACING.call(void 0, 1),
        cursor: "pointer"
      }
    }
  },
  MuiTable: {
    styleOverrides: {
      root: {
        borderSpacing: `0px ${_constants.SPACING.call(void 0, 1)} !important`,
        padding: 0,
        borderCollapse: "separate",
        tableLayout: "fixed"
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      body: {
        borderBottom: "none",
        ":first-child": {
          borderLeftStyle: "solid",
          borderTopLeftRadius: _constants.SPACING.call(void 0, 4),
          borderBottomLeftRadius: _constants.SPACING.call(void 0, 4),
          borderColor: "transparent"
        },
        ":last-child": {
          borderLeftStyle: "solid",
          borderTopRightRadius: _constants.SPACING.call(void 0, 4),
          borderBottomRightRadius: _constants.SPACING.call(void 0, 4),
          borderColor: "transparent"
        }
      }
    }
  },
  MuiDialog: {
    styleOverrides: {
      container: {
        backdropFilter: "blur(4px)"
      }
    }
  }
};
const lightModeComponents = _merge2.default.call(void 0, {}, baseComponents, lightModeVariants);
const darkModeComponents = _merge2.default.call(void 0, {}, baseComponents, darkModeVariants);



exports.darkModeComponents = darkModeComponents; exports.lightModeComponents = lightModeComponents;

"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function DocsIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 24 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M16 23.25H8C4.35 23.25 2.25 21.15 2.25 17.5V7.5C2.25 3.85 4.35 1.75 8 1.75H16C19.65 1.75 21.75 3.85 21.75 7.5V17.5C21.75 21.15 19.65 23.25 16 23.25ZM8 3.25C5.14 3.25 3.75 4.64 3.75 7.5V17.5C3.75 20.36 5.14 21.75 8 21.75H16C18.86 21.75 20.25 20.36 20.25 17.5V7.5C20.25 4.64 18.86 3.25 16 3.25H8Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M18.5 9.75H16.5C14.98 9.75 13.75 8.52 13.75 7V5C13.75 4.59 14.09 4.25 14.5 4.25C14.91 4.25 15.25 4.59 15.25 5V7C15.25 7.69 15.81 8.25 16.5 8.25H18.5C18.91 8.25 19.25 8.59 19.25 9C19.25 9.41 18.91 9.75 18.5 9.75Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12 14.25H8C7.59 14.25 7.25 13.91 7.25 13.5C7.25 13.09 7.59 12.75 8 12.75H12C12.41 12.75 12.75 13.09 12.75 13.5C12.75 13.91 12.41 14.25 12 14.25Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M16 18.25H8C7.59 18.25 7.25 17.91 7.25 17.5C7.25 17.09 7.59 16.75 8 16.75H16C16.41 16.75 16.75 17.09 16.75 17.5C16.75 17.91 16.41 18.25 16 18.25Z"
  }));
}


exports.default = DocsIcon;

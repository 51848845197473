"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _splitbutton = require('../split-button');
var _typography = require('../typography');
var _dialog = require('../dialog');
var _dialogcontent = require('../dialogcontent');
var _iconbutton = require('../iconbutton');
var _icons = require('../../icons');
var _button = require('../button');
var _reactintl = require('react-intl');
var _theme = require('../../theme');
var _foregroundpaper = require('../foreground-paper');
var _ = require('..');
const StyledDialogHeader = _styledcomponents2.default.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const StyledDialogContent = _styledcomponents2.default.call(void 0, _dialogcontent.DialogContent)`
  display: flex;
  align-items: ${(_ref) => {
  let {
    withTitle
  } = _ref;
  return withTitle ? "stretch" : "center";
}};
  justify-content: center;
  padding: 0px;
  ${(_ref2) => {
  let {
    withTitle
  } = _ref2;
  return withTitle && "flex-direction: column;";
}}
  overflow-y: visible;
`;
const StyledDialogColumnContent = _styledcomponents2.default.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  flex-grow: 1;
`;
const StyledDialogTitle = _styledcomponents2.default.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
  margin-bottom: 28px;
`;
const StyledDialog = _styledcomponents2.default.call(void 0, _dialog.Dialog)``;
const StyledPaperModal = _styledcomponents2.default.call(void 0, _foregroundpaper.ForegroundPaper)`
  ${(_ref3) => {
  let {
    theme: {
      palette,
      spacing
    }
  } = _ref3;
  return `
  background-color: ${_theme.colors[palette.mode].background.tertiary};
  padding: ${spacing(10)};
  gap: ${spacing(6)};
  `;
}}
`;
const StyledCloseIconContainer = _styledcomponents2.default.div`
  display: flex;
  position: relative;
`;
const StyledCloseIconButton = _styledcomponents2.default.call(void 0, _iconbutton.IconButton)`
  ${(_ref4) => {
  let {
    theme: {
      spacing
    }
  } = _ref4;
  return `
  position: absolute;
  bottom: ${spacing(5)};
  left: ${spacing(-2)};
  `;
}}
`;
const Modal = (_ref5) => {
  let {
    title,
    open,
    onClose,
    maxWidth,
    showCloseIcon,
    showCloseButton,
    actions,
    fullHeight,
    keepMounted,
    children,
    closeOnBackdrop,
    headerButton,
    actionsAlignment = "vertical",
    extraActions
  } = _ref5;
  const {
    palette: {
      mode
    }
  } = _styledcomponents.useTheme.call(void 0, );
  const handleClose = () => {
    if (onClose && (showCloseButton || showCloseIcon || closeOnBackdrop)) {
      onClose();
    }
  };
  const withTitle = showCloseIcon || !!title;
  const fullHeightProps = fullHeight && {
    sx: {
      height: "90vh"
    }
  } || {};
  return /* @__PURE__ */ _react2.default.createElement(StyledDialog, {
    open,
    fullWidth: true,
    maxWidth: maxWidth || "lg",
    onClose: handleClose,
    PaperProps: fullHeightProps,
    keepMounted,
    PaperComponent: StyledPaperModal
  }, /* @__PURE__ */ _react2.default.createElement(StyledDialogContent, {
    withTitle: withTitle || !!fullHeight
  }, withTitle && /* @__PURE__ */ _react2.default.createElement(StyledDialogTitle, null, /* @__PURE__ */ _react2.default.createElement(StyledDialogHeader, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "h4",
    fontWeight: "bold",
    color: _theme.colors[mode].typography.typo1
  }, title), headerButton), /* @__PURE__ */ _react2.default.createElement(StyledCloseIconContainer, null, /* @__PURE__ */ _react2.default.createElement(StyledCloseIconButton, {
    "aria-label": "close",
    onClick: onClose
  }, /* @__PURE__ */ _react2.default.createElement(_icons.CloseIcon, {
    sx: {
      color: _theme.colors[mode].typography.typo2
    }
  })))), withTitle || !!fullHeight ? /* @__PURE__ */ _react2.default.createElement(StyledDialogColumnContent, null, children) : children), (showCloseButton || !!_optionalChain([actions, 'optionalAccess', _3 => _3.length])) && /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    flexDirection: actionsAlignment === "vertical" ? "column" : "row",
    gap: 3,
    "justify-content": "center",
    alignItems: "center"
  }, showCloseButton && actionsAlignment === "horizontal" && /* @__PURE__ */ _react2.default.createElement(_button.Button, {
    onClick: onClose,
    variant: "outlined",
    size: "large",
    sx: {
      width: "100%"
    }
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "wzEneQ",
    defaultMessage: [{
      "type": 0,
      "value": "Close"
    }]
  })), _optionalChain([actions, 'optionalAccess', _4 => _4.map, 'call', _5 => _5((action, index) => action.options ? /* @__PURE__ */ _react2.default.createElement(_splitbutton.SplitButton, {
    onClick: action.onClick,
    text: action.label,
    disabled: action.disabled,
    variant: _nullishCoalesce(action.variant, () => ( "outlined")),
    color: _nullishCoalesce(action.color, () => ( "primary")),
    options: action.options,
    size: "large",
    block: true,
    key: index
  }) : /* @__PURE__ */ _react2.default.createElement(_button.Button, {
    onClick: action.onClick,
    disabled: action.disabled,
    variant: _nullishCoalesce(action.variant, () => ( "outlined")),
    color: _nullishCoalesce(action.color, () => ( "primary")),
    size: "large",
    key: index,
    sx: {
      width: "100%"
    }
  }, action.label))]), showCloseButton && actionsAlignment === "vertical" && /* @__PURE__ */ _react2.default.createElement(_button.Button, {
    onClick: onClose,
    variant: "contained",
    size: "large",
    sx: {
      width: "100%"
    }
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "wzEneQ",
    defaultMessage: [{
      "type": 0,
      "value": "Close"
    }]
  })), extraActions));
};


exports.Modal = Modal;

"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _containerbox = require('../container-box');
var _ = require('../..');
const focusedStyles = (_ref) => {
  let {
    palette: {
      mode
    }
  } = _ref;
  return `
  background: ${_.colors[mode].background.tertiary};
  border: 1px solid ${_.colors[mode].accentPrimary};
`;
};
const emptyStyles = (_ref2) => {
  let {
    palette: {
      mode
    }
  } = _ref2;
  return `
  background: ${_.colors[mode].background.secondary};
  border: 1px solid ${_.colors[mode].border.border1};
`;
};
const disabledStyles = (_ref3) => {
  let {
    palette: {
      mode
    }
  } = _ref3;
  return `
  background: ${_.colors[mode].background.secondary};
  border: 1px solid ${_.colors[mode].accentPrimary};
  opacity: 0.5;
  cursor: not-allowed;
`;
};
const unfocusedWithValueStyles = (_ref4) => {
  let {
    palette: {
      mode
    }
  } = _ref4;
  return `
  background: ${_.colors[mode].background.secondary};
  border: 1px solid ${_.colors[mode].border.border1};
`;
};
const InputContainer = _styledcomponents2.default.call(void 0, _containerbox.ContainerBox)`
  ${(_ref5) => {
  let {
    theme,
    isFocused,
    disabled,
    hasValue,
    gap
  } = _ref5;
  return `
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
    gap: ${theme.spacing(gap || 3)};
    border-radius: ${theme.spacing(2)};
    position: relative;
    ${hasValue ? emptyStyles(theme) : unfocusedWithValueStyles(theme)}
    ${isFocused && focusedStyles(theme)}
    ${disabled && disabledStyles(theme)}
  `;
}}
`;


exports.InputContainer = InputContainer;

"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
var _constants = require('../constants');
const buildTableVariant = (mode) => ({
  MuiTableContainer: {
    styleOverrides: {
      root: {
        backgroundColor: "transparent !important",
        ".MuiTableCell-root": {
          borderBottom: `1px solid ${_colors.colors[mode].border.border2}`,
          borderRadius: "0px",
          "&:first-of-type": {
            paddingLeft: `${_constants.SPACING.call(void 0, 10)}`,
            borderTopLeftRadius: _constants.SPACING.call(void 0, 2),
            borderBottomLeftRadius: _constants.SPACING.call(void 0, 2)
          },
          "&:last-of-type": {
            paddingRight: `${_constants.SPACING.call(void 0, 10)}`,
            borderTopRightRadius: _constants.SPACING.call(void 0, 2),
            borderBottomRightRadius: _constants.SPACING.call(void 0, 2)
          }
        },
        "&.noSeparateRows": {
          backgroundColor: `${_colors.colors[mode].background.secondary} !important`,
          ".MuiTableRow-root": {
            backgroundColor: `inherit !important`,
            borderRadius: 0,
            "&:last-of-type .MuiTableCell-root": {
              borderBottom: `none`
            }
          },
          ".MuiTableRow-head": {
            backgroundColor: `${_colors.colors[mode].background.secondary} !important`,
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${_colors.colors[mode].border.border2} !important`
            }
          },
          ".MuiTable-root": {
            borderSpacing: "0px !important"
          },
          ".MuiTableCell-root": {
            padding: _constants.SPACING.call(void 0, 4),
            borderRadius: 0
          }
        }
      }
    }
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        backgroundColor: `${_colors.colors[mode].background.secondary} !important`
      },
      head: {
        backgroundColor: `${_colors.colors[mode].background.quarteryNoAlpha} !important`
      }
    }
  }
});


exports.buildTableVariant = buildTableVariant;

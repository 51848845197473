"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _recharts = require('recharts');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _orderBy = require('lodash/orderBy'); var _orderBy2 = _interopRequireDefault(_orderBy);
var _ = require('..');
var _material = require('@mui/material');
var _theme = require('../../theme');
var _popper = require('./popper'); var _popper2 = _interopRequireDefault(_popper);
var _currency = require('../../common/utils/currency');
var _reactintl = require('react-intl');
const StyledBackgroundPaper = _styledcomponents2.default.call(void 0, _.BackgroundPaper)`
  ${(_ref) => {
  let {
    theme: {
      spacing,
      palette
    }
  } = _ref;
  return `
    padding: ${spacing(5)};
    border-color: ${_theme.colors[palette.mode].border.border2};
  `;
}}
  display: flex;
  flex: 1;
`;
const StyledBullet = _styledcomponents2.default.div`
  ${(_ref2) => {
  let {
    fill,
    theme: {
      spacing
    }
  } = _ref2;
  return `
    width: ${spacing(2)};
    height: ${spacing(2)};
    border-radius: ${spacing(10)};
    ${fill && `background-color: ${fill};`}}
  `;
}}
`;
const BorderLinearProgress = _styledcomponents2.default.call(void 0, (0, _material.LinearProgress))((_ref3) => {
  let {
    theme: {
      palette: {
        mode
      },
      spacing
    },
    fill
  } = _ref3;
  return {
    background: _theme.colors[mode].background.primary,
    height: spacing(2),
    [`& .${_material.linearProgressClasses.bar}`]: {
      borderRadius: spacing(3),
      background: fill
    }
  };
});
const StyledContainerBox = _styledcomponents2.default.call(void 0, _.ContainerBox)`
  .recharts-sector {
    outline: none;
  }
`;
const CHART_COLOR_PRIORITIES = {
  dark: [_theme.colors.dark.aqua.aqua700, _theme.colors.dark.aqua.aqua600, _theme.colors.dark.aqua.aqua400, _theme.colors.dark.aqua.aqua300, _theme.colors.dark.aqua.aqua200],
  light: [_theme.colors.light.accent.accent600, _theme.colors.light.accent.primary, _theme.colors.light.accent.accent400, _theme.colors.light.accent.accent200, _theme.colors.light.accent.accent100]
};
const Dashboard = (_ref4) => {
  let {
    data,
    withPie,
    valuesForOther = 4,
    valueFormatter: passedValueFormatter,
    showBalances = true
  } = _ref4;
  const {
    palette: {
      mode
    }
  } = _material.useTheme.call(void 0, );
  const [anchorEl, setAnchorEl] = _react.useState.call(void 0, null);
  const [showPopper, setShowPopper] = _react.useState.call(void 0, false);
  const intl = _reactintl.useIntl.call(void 0, );
  const handlePopperElOver = (event) => {
    setAnchorEl(event.currentTarget);
    setShowPopper(true);
  };
  const handlePopperElOut = () => {
    setAnchorEl(null);
    setShowPopper(false);
  };
  const valueFormatter = _nullishCoalesce(passedValueFormatter, () => ( ((value) => _currency.formatUsdAmount.call(void 0, {
    amount: value,
    intl
  }))));
  const mappedData = _react.useMemo.call(void 0, () => {
    const orderedData = _orderBy2.default.call(void 0, data, ["value"], ["desc"]);
    let totalValue = 0;
    const dataWithBreakdown = orderedData.reduce((acc, dataPoint) => {
      totalValue += dataPoint.value;
      if (acc.length < valuesForOther) {
        acc.push(dataPoint);
      } else if (acc.length === valuesForOther && orderedData.length > valuesForOther + 1) {
        acc.push({
          name: "Other",
          value: dataPoint.value,
          isOther: true,
          breakdown: [dataPoint]
        });
      } else if (acc.length > valuesForOther) {
        const other = {
          ...acc[valuesForOther]
        };
        other.value += dataPoint.value;
        other.breakdown = [...other.breakdown, dataPoint];
        acc[valuesForOther] = other;
      }
      return acc;
    }, []);
    return dataWithBreakdown.map((dataPoint, index) => ({
      ...dataPoint,
      fill: CHART_COLOR_PRIORITIES[mode][index] || CHART_COLOR_PRIORITIES[mode][CHART_COLOR_PRIORITIES[mode].length - 1],
      relativeValue: dataPoint.value * 100 / totalValue
    }));
  }, [data, valuesForOther, mode]);
  const pieValue = mappedData.reduce((acc, dataPoint) => acc + dataPoint.value, 0);
  return /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    gap: 6,
    flex: 1
  }, withPie && /* @__PURE__ */ _react2.default.createElement(_.Hidden, {
    mdDown: true
  }, /* @__PURE__ */ _react2.default.createElement(StyledContainerBox, null, /* @__PURE__ */ _react2.default.createElement(_recharts.ResponsiveContainer, {
    minHeight: 150,
    minWidth: 150,
    height: "100%"
  }, /* @__PURE__ */ _react2.default.createElement(_recharts.PieChart, null, /* @__PURE__ */ _react2.default.createElement(_recharts.Pie, {
    data: mappedData,
    dataKey: "value",
    innerRadius: 65,
    paddingAngle: 1,
    outerRadius: 75,
    cursor: "pointer",
    fill: _theme.colors[mode].violet.violet200
  }, mappedData.map((entry, index) => /* @__PURE__ */ _react2.default.createElement(_recharts.Cell, {
    key: `cell-${index}`,
    fill: entry.fill,
    stroke: "transparent"
  })), /* @__PURE__ */ _react2.default.createElement(_recharts.Label, {
    value: showBalances ? valueFormatter(pieValue) : "-",
    position: "center",
    fontSize: "0.875rem",
    fontWeight: 700,
    fontFamily: "Inter",
    color: _theme.colors[mode].typography.typo2,
    fill: _theme.colors[mode].typography.typo2
  })))))), /* @__PURE__ */ _react2.default.createElement(StyledBackgroundPaper, {
    variant: "outlined"
  }, /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    flexDirection: "column",
    alignSelf: "stretch",
    flex: 1,
    justifyContent: "space-around"
  }, mappedData.map((dataPoint) => /* @__PURE__ */ _react2.default.createElement(_.Grid, {
    container: true,
    alignItems: "center",
    onMouseOver: (event) => {
      if ("isOther" in dataPoint) {
        handlePopperElOver(event);
      }
    },
    onMouseOut: () => {
      if ("isOther" in dataPoint) {
        handlePopperElOut();
      }
    },
    key: dataPoint.name
  }, /* @__PURE__ */ _react2.default.createElement(_.Grid, {
    item: true,
    xs: 1
  }, /* @__PURE__ */ _react2.default.createElement(StyledBullet, {
    fill: dataPoint.fill
  })), /* @__PURE__ */ _react2.default.createElement(_.Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodySmallRegular"
  }, dataPoint.name)), /* @__PURE__ */ _react2.default.createElement(_.Grid, {
    item: true,
    flex: 1
  }, "isOther" in dataPoint && /* @__PURE__ */ _react2.default.createElement(_.Popper, {
    id: "other-popper",
    open: showPopper,
    anchorEl
  }, /* @__PURE__ */ _react2.default.createElement(_popper2.default, {
    breakdown: dataPoint.breakdown,
    valueFormatter
  })), /* @__PURE__ */ _react2.default.createElement(BorderLinearProgress, {
    variant: "determinate",
    value: dataPoint.relativeValue,
    fill: dataPoint.fill
  })), /* @__PURE__ */ _react2.default.createElement(_.Grid, {
    item: true,
    xs: 3,
    sx: {
      textAlign: "right"
    }
  }, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodySmallRegular"
  }, showBalances ? valueFormatter(dataPoint.value) : /* @__PURE__ */ _react2.default.createElement(_.HiddenNumber, {
    size: "small"
  }))))))));
};
const skeletonRows = Array.from(Array(3).keys());
const DashboardSkeleton = (_ref5) => {
  let {
    withPie
  } = _ref5;
  return /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    gap: 6,
    flex: 1
  }, withPie && /* @__PURE__ */ _react2.default.createElement(_.Skeleton, {
    variant: "circular",
    width: 150,
    height: 150
  }), /* @__PURE__ */ _react2.default.createElement(StyledBackgroundPaper, {
    variant: "outlined"
  }, /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    flexDirection: "column",
    alignSelf: "stretch",
    flex: 1,
    justifyContent: "space-around"
  }, skeletonRows.map((key) => /* @__PURE__ */ _react2.default.createElement(_.Grid, {
    container: true,
    alignItems: "center",
    key,
    columnSpacing: 3
  }, /* @__PURE__ */ _react2.default.createElement(_.Grid, {
    item: true,
    xs: 1
  }, /* @__PURE__ */ _react2.default.createElement(_.Skeleton, {
    variant: "rounded"
  })), /* @__PURE__ */ _react2.default.createElement(_.Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ _react2.default.createElement(_.Skeleton, {
    variant: "text",
    animation: "wave"
  })), /* @__PURE__ */ _react2.default.createElement(_.Grid, {
    item: true,
    flex: 1
  }, /* @__PURE__ */ _react2.default.createElement(_.Skeleton, {
    variant: "text",
    animation: "wave"
  })), /* @__PURE__ */ _react2.default.createElement(_.Grid, {
    item: true,
    xs: 3,
    sx: {
      textAlign: "right"
    }
  }, /* @__PURE__ */ _react2.default.createElement(_.Skeleton, {
    variant: "text",
    animation: "wave"
  })))))));
};




exports.CHART_COLOR_PRIORITIES = CHART_COLOR_PRIORITIES; exports.Dashboard = Dashboard; exports.DashboardSkeleton = DashboardSkeleton;

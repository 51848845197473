"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
const buildSvgIconVariant = (mode) => ({
  MuiSvgIcon: {
    styleOverrides: {
      colorDisabled: {
        color: _colors.colors[mode].typography.typo5
      },
      root: {
        "&.MuiSvgIcon-colorSuccess": {
          color: _colors.colors[mode].semantic.success.darker
        },
        "&.MuiSvgIcon-colorWarning": {
          color: _colors.colors[mode].semantic.warning.darker
        },
        "&.MuiSvgIcon-colorError": {
          color: _colors.colors[mode].semantic.error.darker
        },
        "&.MuiSvgIcon-colorInfo": {
          color: _colors.colors[mode].typography.typo3
        }
      }
    }
  }
});


exports.buildSvgIconVariant = buildSvgIconVariant;

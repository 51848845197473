"use strict";Object.defineProperty(exports, "__esModule", {value: true});var TransactionTypes = /* @__PURE__ */ ((TransactionTypes2) => {
  TransactionTypes2["approveToken"] = "APPROVE_TOKEN";
  TransactionTypes2["approveTokenExact"] = "APPROVE_TOKEN_EXACT";
  TransactionTypes2["transferToken"] = "TRANSFER_TOKEN";
  TransactionTypes2["newPosition"] = "NEW_POSITION";
  TransactionTypes2["newPair"] = "NEW_PAIR";
  TransactionTypes2["wrapEther"] = "WRAP_ETHER";
  TransactionTypes2["terminatePosition"] = "TERMINATE_POSITION";
  TransactionTypes2["withdrawPosition"] = "WITHDRAW_POSITION";
  TransactionTypes2["addFundsPosition"] = "ADD_FUNDS_POSITION";
  TransactionTypes2["noOp"] = "NO_OP";
  TransactionTypes2["removeFunds"] = "REMOVE_FUNDS";
  TransactionTypes2["modifySwapsPosition"] = "MODIFY_SWAPS_POSITION";
  TransactionTypes2["modifyRateAndSwapsPosition"] = "MODIFY_RATE_AND_SWAPS_POSITION";
  TransactionTypes2["transferPosition"] = "TRANSFER_POSITION";
  TransactionTypes2["approveCompanion"] = "APPROVE_COMPANION";
  TransactionTypes2["modifyPermissions"] = "MODIFY_PERMISSIONS";
  TransactionTypes2["migratePosition"] = "MIGRATE_POSITION";
  TransactionTypes2["migratePositionYield"] = "MIGRATE_POSITION_YIELD";
  TransactionTypes2["withdrawFunds"] = "WITHDRAW_FUNDS";
  TransactionTypes2["resetPosition"] = "RESET_POSITION";
  TransactionTypes2["swap"] = "SWAP";
  TransactionTypes2["wrap"] = "WRAP";
  TransactionTypes2["unwrap"] = "UNWRAP";
  TransactionTypes2["eulerClaimTerminateMany"] = "EULER_CLAIM_TERMINATE_MANY";
  TransactionTypes2["eulerClaimPermitMany"] = "EULER_CLAIM_PERMIT_MANY";
  TransactionTypes2["eulerClaimApproveMigrator"] = "EULER_CLAIM_APPROVE_MIGRATOR";
  TransactionTypes2["eulerClaimClaimFromMigrator"] = "EULER_CLAIM_CLAIM_FROM_MIGRATOR";
  TransactionTypes2["claimCampaign"] = "CLAIM_CAMPAIGN";
  return TransactionTypes2;
})(TransactionTypes || {});
var TransactionApplicationIdentifier = /* @__PURE__ */ ((TransactionApplicationIdentifier2) => {
  TransactionApplicationIdentifier2["TRANSFER"] = "TRANSFER";
  TransactionApplicationIdentifier2["SWAP"] = "SWAP";
  TransactionApplicationIdentifier2["DCA"] = "DCA";
  return TransactionApplicationIdentifier2;
})(TransactionApplicationIdentifier || {});



exports.TransactionApplicationIdentifier = TransactionApplicationIdentifier; exports.TransactionTypes = TransactionTypes;

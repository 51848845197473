"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function InfoCircleIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 19 18",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector",
    d: "M9.36719 17.0625C4.91969 17.0625 1.30469 13.4475 1.30469 9C1.30469 4.5525 4.91969 0.9375 9.36719 0.9375C13.8147 0.9375 17.4297 4.5525 17.4297 9C17.4297 13.4475 13.8147 17.0625 9.36719 17.0625ZM9.36719 2.0625C5.54219 2.0625 2.42969 5.175 2.42969 9C2.42969 12.825 5.54219 15.9375 9.36719 15.9375C13.1922 15.9375 16.3047 12.825 16.3047 9C16.3047 5.175 13.1922 2.0625 9.36719 2.0625Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_2",
    d: "M9.36719 10.3125C9.05969 10.3125 8.80469 10.0575 8.80469 9.75V6C8.80469 5.6925 9.05969 5.4375 9.36719 5.4375C9.67469 5.4375 9.92969 5.6925 9.92969 6V9.75C9.92969 10.0575 9.67469 10.3125 9.36719 10.3125Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_3",
    d: "M9.36719 12.7502C9.26969 12.7502 9.17219 12.7277 9.08219 12.6902C8.99219 12.6527 8.90969 12.6002 8.83469 12.5327C8.76719 12.4577 8.71469 12.3827 8.67719 12.2852C8.63969 12.1952 8.61719 12.0977 8.61719 12.0002C8.61719 11.9027 8.63969 11.8052 8.67719 11.7152C8.71469 11.6252 8.76719 11.5427 8.83469 11.4677C8.90969 11.4002 8.99219 11.3477 9.08219 11.3102C9.26219 11.2352 9.47219 11.2352 9.65219 11.3102C9.74219 11.3477 9.82469 11.4002 9.89969 11.4677C9.96719 11.5427 10.0197 11.6252 10.0572 11.7152C10.0947 11.8052 10.1172 11.9027 10.1172 12.0002C10.1172 12.0977 10.0947 12.1952 10.0572 12.2852C10.0197 12.3827 9.96719 12.4577 9.89969 12.5327C9.82469 12.6002 9.74219 12.6527 9.65219 12.6902C9.56219 12.7277 9.46469 12.7502 9.36719 12.7502Z"
  }));
}


exports.default = InfoCircleIcon;

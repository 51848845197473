"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _typography = require('../typography');
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _icons = require('../../icons');
var _theme = require('../../theme');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
const StyledArrowCircle = _styledcomponents2.default.div`
  ${(_ref) => {
  let {
    theme: {
      palette,
      spacing
    }
  } = _ref;
  return `
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${_theme.colors[palette.mode].background.secondary};
  border-radius: 50%;
  border: 1px solid ${_theme.colors[palette.mode].border.border1};
  width: ${spacing(10)};
  height: ${spacing(10)};
  transition: .5s;
  box-shadow: ${_theme.colors[palette.mode].dropShadow.dropShadow100};
  `;
}}
`;
const ControlContainer = _styledcomponents2.default.div`
  ${(_ref2) => {
  let {
    theme: {
      palette,
      spacing
    }
  } = _ref2;
  return `
  display: flex;
  align-items: center;
  color: ${_theme.colors[palette.mode].accentPrimary};
  gap: ${spacing(3)};
  cursor: pointer;
  :hover {
    ${StyledArrowCircle} {
      background-color: ${_theme.colors[palette.mode].background.tertiary};
      transform: scale(1.05);
    }
  }
  `;
}}
`;
const StyledBackIcon = _styledcomponents2.default.call(void 0, _icons.KeyboardBackspaceIcon)`
  ${(_ref3) => {
  let {
    theme: {
      palette,
      spacing
    }
  } = _ref3;
  return `
color: ${_theme.colors[palette.mode].accentPrimary};
gap: ${spacing(3)};
`;
}}
`;
const BackControl = (_ref4) => {
  let {
    onClick,
    label
  } = _ref4;
  return /* @__PURE__ */ _react2.default.createElement(ControlContainer, {
    onClick
  }, /* @__PURE__ */ _react2.default.createElement(StyledArrowCircle, null, /* @__PURE__ */ _react2.default.createElement(StyledBackIcon, null)), label && /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "bodyRegular",
    color: (_ref5) => {
      let {
        palette: {
          mode
        }
      } = _ref5;
      return _theme.colors[mode].accentPrimary;
    }
  }, label));
};


exports.BackControl = BackControl;

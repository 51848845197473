"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
var _constants = require('../constants');
const buildButtonVariant = (mode) => ({
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
        padding: `${_constants.SPACING.call(void 0, 3)} ${_constants.SPACING.call(void 0, 6)}`
      },
      endIcon: {
        marginLeft: `0px`
      },
      sizeLarge: {
        fontSize: "1rem",
        lineHeight: 2,
        padding: `${_constants.SPACING.call(void 0, 3)} ${_constants.SPACING.call(void 0, 6)}`
      },
      sizeMedium: {
        padding: `${_constants.SPACING.call(void 0, 2.25)} ${_constants.SPACING.call(void 0, 4.5)}`
      },
      sizeSmall: {
        padding: `${_constants.SPACING.call(void 0, 1.5)} ${_constants.SPACING.call(void 0, 3)}`,
        minWidth: 0
      },
      outlinedPrimary: {
        border: `1px solid ${_colors.colors[mode].accent.accent400}`,
        color: _colors.colors[mode].accent.primary,
        fontWeight: 700,
        "&:disabled": {
          border: `1px solid ${_colors.colors[mode].typography.typo5}`,
          color: _colors.colors[mode].typography.typo1,
          opacity: 0.5
        },
        "&:hover": {
          backgroundColor: _colors.colors[mode].accent.accent200,
          color: _colors.colors[mode].accent.accent600
        }
      },
      containedPrimary: {
        backgroundColor: _colors.colors[mode].accent.primary,
        boxShadow: _colors.colors[mode].dropShadow.dropShadow200,
        color: _colors.colors[mode].accent.accent100,
        fontWeight: 700,
        "&:disabled": {
          border: `1px solid ${_colors.colors[mode].typography.typo3}`,
          color: _colors.colors[mode].typography.typo1,
          opacity: 0.7
        },
        "&:hover": {
          backgroundColor: _colors.colors[mode].accent.accent600,
          color: _colors.colors[mode].accent.accent200
        }
      },
      containedSecondary: {
        backgroundColor: _colors.colors[mode].background.secondary,
        border: `1px solid ${_colors.colors[mode].accent.accent400}`,
        color: _colors.colors[mode].accentPrimary,
        boxShadow: "none",
        fontWeight: 700,
        "&:disabled": {
          border: `1px solid ${_colors.colors[mode].typography.typo3}`,
          color: _colors.colors[mode].typography.typo1,
          opacity: 0.7
        },
        "&:hover": {
          backgroundColor: _colors.colors[mode].accent.accent200,
          color: _colors.colors[mode].accent.accent600
        },
        "&:active": {
          backgroundColor: _colors.colors[mode].accent.primary,
          color: _colors.colors[mode].accent.accent100
        }
      },
      containedError: {
        "&:disabled": {
          border: `1px solid ${_colors.colors[mode].semanticBackground.error}`,
          color: _colors.colors[mode].semantic.error.darker,
          backgroundColor: _colors.colors[mode].semanticBackground.error,
          opacity: 0.7
        }
      },
      textPrimary: {
        color: _colors.colors[mode].accent.primary,
        fontWeight: 700,
        "&:disabled": {
          color: _colors.colors[mode].typography.typo1,
          opacity: 0.3
        },
        "&:hover": {
          backgroundColor: _colors.colors[mode].accent.accent200,
          color: _colors.colors[mode].accent.accent600
        }
      }
    }
  }
});


exports.buildButtonVariant = buildButtonVariant;

"use strict";Object.defineProperty(exports, "__esModule", {value: true});const DEFAULT_SPACING = 4;
const DEFAULT_BORDER_RADIUS = 16;
const SPACING = (value) => `${DEFAULT_SPACING * value}px`;
const MAX_FORM_WIDTH = SPACING(158);





exports.DEFAULT_BORDER_RADIUS = DEFAULT_BORDER_RADIUS; exports.DEFAULT_SPACING = DEFAULT_SPACING; exports.MAX_FORM_WIDTH = MAX_FORM_WIDTH; exports.SPACING = SPACING;

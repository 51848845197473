"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _paper = require('../paper');
var _theme = require('../../theme');
var _constants = require('../../theme/constants');
var _ = require('../');
const StyledPaperContainer = _styledcomponents2.default.call(void 0, _paper.Paper)`
  padding: 16px;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  flex-grow: 1;
  backdrop-filter: blur(6px);
  display: flex;
  gap: 24px;
  flex-direction: ${(_ref) => {
  let {
    $column
  } = _ref;
  return $column ? "column" : "row";
}};
  ${(_ref2) => {
  let {
    $align
  } = _ref2;
  return $align ? "align-self: flex-start;" : "";
}}
`;
const StyledContentContainer = _styledcomponents2.default.div`
  padding: ${_theme.baseTheme.spacing(4)};
  border-radius: ${_theme.baseTheme.spacing(8)};
`;
const StyledFormContainer = _styledcomponents2.default.call(void 0, _.Grid).attrs({
  flex: "1",
  container: true,
  alignItems: "stretch",
  direction: "row"
})`
  max-width: ${_constants.MAX_FORM_WIDTH};
`;
const StyledNonFormContainer = _styledcomponents2.default.call(void 0, _.Grid).attrs({
  flex: "1",
  container: true,
  alignItems: "stretch",
  direction: "row"
})``;
const ColorCircle = _styledcomponents2.default.div`
  ${(_ref3) => {
  let {
    color,
    size,
    theme: {
      spacing
    }
  } = _ref3;
  return `
    width: ${spacing(size || 4)};
    height: ${spacing(size || 4)};
    background-color: ${color || "transparent"};
    border-radius: 50%;
    display: inline-block
  `;
}}
`;






exports.ColorCircle = ColorCircle; exports.StyledContentContainer = StyledContentContainer; exports.StyledFormContainer = StyledFormContainer; exports.StyledNonFormContainer = StyledNonFormContainer; exports.StyledPaperContainer = StyledPaperContainer;

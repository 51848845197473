"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _containerbox = require('../container-box');
var _material = require('@mui/material');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _backgroundpaper = require('../background-paper');
var _theme = require('../../theme');
var _notistack = require('notistack');
var _reactintl = require('react-intl');
var _debounce = require('lodash/debounce'); var _debounce2 = _interopRequireDefault(_debounce);
const StyledOption = _styledcomponents2.default.call(void 0, _backgroundpaper.BackgroundPaper).attrs({
  variant: "outlined"
})`
  ${(_ref) => {
  let {
    theme: {
      palette,
      spacing
    },
    $selected
  } = _ref;
  return `
    padding: ${spacing(3)};
    border-radius: ${spacing(2)};
    cursor: pointer;
    outline-width: 1px;
    transition: all 0.3s;
    display: flex;
    ${$selected && `
    background-color: ${_theme.colors[palette.mode].background.tertiary};
    outline-color ${_theme.colors[palette.mode].border.accent};
    `}
`;
}}
`;
const StyledRatingDescriptor = _styledcomponents2.default.call(void 0, _material.Typography).attrs({
  variant: "bodyExtraSmall"
})`
  color: ${(_ref2) => {
  let {
    theme
  } = _ref2;
  return _theme.colors[theme.palette.mode].typography.typo2;
}};
`;
const CustomerSatisfaction = (_ref3) => {
  let {
    options,
    mainQuestion,
    ratingDescriptors = [],
    onClickOption
  } = _ref3;
  const [optionSelected, setOptionSelected] = _react.useState.call(void 0, null);
  const thankYouRef = _react.useRef.call(void 0, false);
  const snackbar = _notistack.useSnackbar.call(void 0, );
  const debouncedClickOption = _react.useCallback.call(void 0, _debounce2.default.call(void 0, onClickOption, 3e3), [onClickOption]);
  const handleOptionClick = (value) => {
    const prevValue = optionSelected;
    setOptionSelected((prev) => prev === value ? null : value);
    try {
      debouncedClickOption(value);
      thankYouRef.current = true;
    } catch (e) {
      console.error(e);
      snackbar.enqueueSnackbar({
        variant: "error",
        message: /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
          id: "x1gsRB",
          defaultMessage: [{
            "type": 0,
            "value": "We weren't able to save your feedback. Please try again later"
          }]
        })
      });
      setOptionSelected(prevValue);
      thankYouRef.current = false;
    }
  };
  return /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
    flexDirection: "column",
    gap: 3,
    alignItems: "center"
  }, !thankYouRef.current ? /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, /* @__PURE__ */ _react2.default.createElement(_material.Typography, {
    variant: "bodySmallLabel",
    textAlign: "center"
  }, mainQuestion), /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
    flexDirection: "column",
    gap: 2
  }, /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
    gap: 2
  }, options.map((option) => /* @__PURE__ */ _react2.default.createElement(StyledOption, {
    key: option.value,
    $selected: optionSelected === option.value,
    elevation: optionSelected === option.value ? 1 : 0,
    onClick: () => void handleOptionClick(option.value)
  }, option.label))), ratingDescriptors.length > 0 && /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
    justifyContent: "space-between"
  }, ratingDescriptors.map((label, index) => /* @__PURE__ */ _react2.default.createElement(StyledRatingDescriptor, {
    key: index
  }, label))))) : /* @__PURE__ */ _react2.default.createElement(_material.Zoom, {
    in: thankYouRef.current,
    unmountOnExit: true,
    mountOnEnter: true
  }, /* @__PURE__ */ _react2.default.createElement(_material.Typography, {
    variant: "h6Bold",
    textAlign: "center"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "890gPv",
    defaultMessage: [{
      "type": 0,
      "value": "Thank you!"
    }]
  }))));
};
var customer_satisfaction_default = CustomerSatisfaction;


exports.default = customer_satisfaction_default;

"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
const buildChipVariant = (mode) => ({
  MuiChip: {
    styleOverrides: {
      colorPrimary: {
        color: _colors.colors[mode].typography.typo2,
        backgroundColor: _colors.colors[mode].background.tertiary,
        border: `1.5px solid ${_colors.colors[mode].border.border2}`
      },
      colorSecondary: {
        color: _colors.colors[mode].typography.typo3,
        backgroundColor: _colors.colors[mode].background.quartery,
        border: `1.5px solid ${_colors.colors[mode].border.border1}`
      },
      colorSuccess: {
        color: _colors.colors[mode].semantic.success.darker,
        backgroundColor: _colors.colors[mode].semanticBackground.success
      },
      colorWarning: {
        color: _colors.colors[mode].semantic.warning.darker,
        backgroundColor: _colors.colors[mode].semanticBackground.warning
      },
      colorError: {
        color: _colors.colors[mode].semantic.error.darker,
        backgroundColor: _colors.colors[mode].semanticBackground.error
      }
    }
  }
});


exports.buildChipVariant = buildChipVariant;

"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactvirtuoso = require('react-virtuoso');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
const StyledList = _styledcomponents2.default.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${(_ref) => {
  let {
    theme: {
      spacing
    }
  } = _ref;
  return `
    gap: ${spacing(2)}
  `;
}}
`;
const VirtuosoComponents = {
  List: /* @__PURE__ */ _react.forwardRef.call(void 0, function VirtuosoList(props, ref) {
    return /* @__PURE__ */ _react2.default.createElement(StyledList, _extends({}, props, {
      ref
    }));
  })
};
const VirtualizedList = (_ref2) => {
  let {
    data,
    itemContent,
    fetchMore,
    context
  } = _ref2;
  return /* @__PURE__ */ _react2.default.createElement(_reactvirtuoso.Virtuoso, {
    style: {
      height: "100%"
    },
    data,
    itemContent,
    endReached: fetchMore,
    context,
    components: VirtuosoComponents
  });
};


exports.VirtualizedList = VirtualizedList;
